export const Photos = [
    {
        "id": 0,
        "imageUrl": require("../images/about/0.jpg"),
    },
    {
        "id": 1,
        "imageUrl": require("../images/about/1.jpg"),
    },
    {
        "id": 2,
        "imageUrl": require("../images/about/2.jpg"),
    },
    {
        "id": 3,
        "imageUrl": require("../images/about/3.jpg"),
    },
    {
        "id": 4,
        "imageUrl": require("../images/about/4.jpg"),
    },
    {
        "id": 5,
        "imageUrl": require("../images/about/5.jpg"),
    },
    {
        "id": 6,
        "imageUrl": require("../images/about/6.jpg"),
    },
    {
        "id": 7,
        "imageUrl": require("../images/about/7.jpg"),
    },
]    